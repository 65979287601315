.App {
  text-align: center;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-bar {
  display: flex;  
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  position: relative;
  background-color: #212529;
  padding-bottom: .4rem;
  padding-top: .4rem;
}

.nav-brand {
  margin-right: 1rem;
  margin-left: 1rem;
  text-decoration: none;
  justify-content: center;
}

.nav-brand object {
  height: 28px;
  margin-top: -3px;
  vertical-align: text-top;
}

.nav-brand div {
  display: inline;
  color: #fff;
  padding-left: 0.3125rem;
  font-size: 1.25rem;
  white-space: nowrap;
}

.container-body {
  margin-left: auto;
  margin-right: auto;
  min-height: 90vh;
  flex-direction: column;
  flex-grow: 1;
}

div.btn-align {
  align-items: right;
  display: inline-block;
  vertical-align: top;
}

.btn-align button:last-child {
   display: block;
}